import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { useDispatch, useSelector } from 'react-redux';
import {
    dataProcessingModalState,
    setDataProcessingModal,
} from '../../store/commonSlice';
import { AutoComplete } from "primereact/autocomplete";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from 'react';
import { useGenerateServiceIDMutation, useGetAllServiceIDQuery } from "../../store/settingApi";
import { useGetAllUsersQuery } from '../../store/UserApi';
import { Button } from 'primereact/button';
import InputField from '../../components/input-field/input-field';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useGetAllCostCentersQuery } from '../../store/apiSlice';
import AutoCompleteCostCenterId from '../../components/autocomplete-costcenter-id';
import { useCreateCrossChargeMutation, useUpdateCrossChargeMutation } from '../../store/chargingReportApi';
import { formatDateDDMMYYYY, getFullMonth } from '../../utils/utility-function';
import useGetOU from '../../hooks/useGetOU';

const AddOrEditCrossCharge = ({ editData, showDetails }: { editData: any, showDetails: Boolean }) => {

	const ou = useGetOU();

    const dispatch = useDispatch();
    const serviceModal = useSelector(dataProcessingModalState);

    const [ownerData, setOwnerData] = useState<any>(undefined);
    const [serviceName, setServiceName] = useState<any>(undefined);
    const [description, setDescription] = useState<any>(undefined);
    const [status, setStatus] = useState<any>(undefined);
    const [service, setService] = useState<any>(undefined);
    const [costs, setCosts] = useState<any>(undefined);
    const [date, setDate] = useState<any>(undefined);
    const [SAID, setSAID] = useState<any>(undefined);
    const [CONumber, setCONumber] = useState<any>(undefined);
    const [additionalInfo, setAdditionalInfo] = useState<any>(undefined);

    const [showInfo, setShowInfo] = useState<any>(showDetails === undefined ? false : showDetails);

    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();

    let minDate = new Date();
    minDate.setMonth(0);
    minDate.setFullYear(year);

    const { data: allCostCenters = [] } = useGetAllCostCentersQuery({ou});
    const { data: serviceDetails = [], isLoading } = useGetAllServiceIDQuery();

    const [costCenterData, setCostCenterData] = useState<any>(undefined);
    const [targetCostCenterData, setTargetCostCenterData] = useState<any>(undefined);
    const [ccSelectedValue, setCcSelectedValue] = useState<any>(null);
    const [ccSelectedTargetValue, setCcSelectedTargetValue] = useState<any>(null);
    const [ccFilteredValues, setCcFilteredValues] = useState<any>(null);

    const ccSearchValues = (event: any) => {
        setTimeout(() => {
            let _filteredValues;
            let inputList = allCostCenters
                .filter((obj: any) => obj.isvisible)
                .map((item: any) => ({
                    costCenterId: item.id,
                    costCenter: item.target_costcenter,
                }))
            if (!event.query.trim().length) {
                _filteredValues = [...inputList];
            } else {
                _filteredValues = inputList.filter((listValue) => {
                    return listValue.costCenter.toLowerCase().includes(event.query.toLowerCase());
                }
                );
            }
            setCcFilteredValues(_filteredValues);
        }, 100);
    };

    const [addNewCrossCharge, { isLoading: isAddingNewCrossCharge }] = useCreateCrossChargeMutation();
    const [updateCrossCharge, { isLoading: isUpdatingCrossCharge }] = useUpdateCrossChargeMutation();
    
    const resetFormData = () => {
        setServiceName(undefined);
        setService(undefined);
        setAdditionalInfo(undefined);
        setSAID(undefined);
        setCONumber(undefined);
        setDate(undefined);
        setCosts(undefined);
        setCostCenterData(undefined);
        setTargetCostCenterData(undefined);
        setCcSelectedValue(null)
        setCcSelectedTargetValue(null)
    }

    const createCrossCharge = async () => {
        if (CONumber && CONumber.length > 0 && CONumber.length < 8) {
            toast.error('CO Number should be a 8 digit number!')
        }
        else {
            await addNewCrossCharge({
                serviceId: service,
                serviceName: serviceName,
                billingMonth: `${getFullMonth(date)}`,
                billingYear: date.getFullYear(),
                billingAmount: costs,
                targetCostcenter: targetCostCenterData.costCenter,
                sourceCostcenter: costCenterData.costCenter,
                said: SAID,
                coNumber: CONumber,
                additionalInfo
            })
                .unwrap()
                .then((payload: any) => {
                    toast.success('New Cross Charge added successfully', {
                        position: 'bottom-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    resetFormData();
                    dispatch(setDataProcessingModal({ addCrossCharge: false }))
                })
                .catch((e: any) =>
                    toast.error('Failed adding new Cross Charge', {
                        position: 'bottom-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                );
        }
    };

    const modifyCrossCharge = async () => {
        if (CONumber.length > 0 && CONumber.length < 8) {
            toast.error('CO Number should be a 8 digit number!')
        }
        else {
            await updateCrossCharge({
                id: editData.id,
                serviceId: service,
                serviceName: serviceName,
                billingMonth: `${getFullMonth(date)}`,
                billingYear: date.getFullYear(),
                billingAmount: costs,
                targetCostcenter: targetCostCenterData.costCenter,
                sourceCostcenter: costCenterData.costCenter,
                said: SAID,
                coNumber: CONumber,
                additionalInfo
            })
                .unwrap()
                .then((payload: any) => {
                    toast.success('Cross Charge updated successfully', {
                        position: 'bottom-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    resetFormData();
                    dispatch(setDataProcessingModal({ addCrossCharge: false }))
                })
                .catch((e: any) =>
                    toast.error('Failed updating Cross Charge', {
                        position: 'bottom-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                );
        }
    }


    const costCenterTemplate = (item: any) => {
        return (
            <div className="country-item">
                <div>{item.costCenter}</div>
            </div>
        );
    };

    const disableUpdate = () => {
        if (
            service === editData.serviceId &&
            serviceName === editData.serviceName &&
            costs === editData.billingAmount &&
            SAID === editData.said &&
            CONumber === editData.coNumber &&
            additionalInfo === editData.additionalInfo &&
            `${getFullMonth(date)}-${date.getFullYear()}` === `${editData.billingMonth}-${editData.billingYear}` &&
            ccSelectedValue?.costCenter === editData.sourceCostcenter &&
            ccSelectedTargetValue?.costCenter === editData.targetCostcenter
        ) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        setShowInfo(showDetails);
        if (editData) {
            setService(editData.serviceId);
            setServiceName(editData.serviceName);
            setCosts(editData.billingAmount);
            setAdditionalInfo(editData.additionalInfo);
            setCONumber(editData.coNumber);
            setDate(new Date(`${editData.billingMonth}-${editData.billingYear}`));
            setSAID(editData.said);
            setCcSelectedValue({ costCenter: editData.sourceCostcenter })
            setCcSelectedTargetValue({ costCenter: editData.targetCostcenter })
            setCostCenterData({ costCenter: editData.sourceCostcenter })
            setTargetCostCenterData({ costCenter: editData.targetCostcenter })
        } else {
            resetFormData();
        }
    }, [serviceModal.addCrossCharge])

    const statusList = [
        { status: "Active", value: "Active" },
        { status: "InActive", value: "InActive" },
    ]

    return (
        <>
            <Dialog
                header={showInfo ? "Cross Charge additional fields" : editData ? "Update Cross Charge" : "Add Cross Charge"}
                visible={serviceModal.addCrossCharge}
                className="flex justify-content-center"
                style={{ width: '60vw' }}
                onHide={() => {
                    resetFormData();
                    dispatch(setDataProcessingModal({ addCrossCharge: false }))
                }}
            >

                <div className="p-fluid grid formgrid">
                    {!showInfo &&
                        <div className='flex justify-content-end col-12'>
                            <div className='flex col-2'><span style={{ color: "red" }}>*</span>&nbsp;Required fields</div>
                        </div>}
                    <div className="grid col-12 mt-4">

                        <div className={`field col-4`}>
                            <span className="p-float-label">
                                <Dropdown
                                    value={service}
                                    options={serviceDetails.map((obj) => {
                                        return {
                                            "serviceId": obj.serviceId,
                                            "id": obj.id,
                                            "value": obj.serviceId
                                        }
                                    })}
                                    onChange={(item) => {
                                        setService(item.value);
                                        const selectedService: any = serviceDetails.filter((obj: any) => obj.serviceId === item.value)
                                        setServiceName(selectedService[0].serviceName)
                                    }}
                                    optionLabel="serviceId"
                                    id="serviceId"
                                    disabled={showInfo}
                                    name="serviceId"
                                />
                                <label htmlFor="serviceId">Service ID{!showInfo && <span style={{ color: "red" }}>*</span>}</label>
                            </span>
                        </div>

                        <div className={`field col-4`}>
                            <span className="p-float-label">
                                <InputText
                                    id="serviceName"
                                    name="serviceName"
                                    value={serviceName}
                                    disabled
                                />
                                <label htmlFor="serviceName">Service Name{!showInfo && <span style={{ color: "red" }}>*</span>}</label>
                            </span>
                        </div>

                        <div className={`field col-4`}>
                            <span className="p-float-label">
                                <InputNumber
                                    id="costs"
                                    disabled={showInfo}
                                    name="costs"
                                    value={costs}
                                    locale="de-DE"
                                    minFractionDigits={2}
                                    onValueChange={(e) => e.preventDefault()}
                                    onChange={(item) => {
                                        setCosts(item.value);
                                    }}
                                />
                                <label htmlFor="costs">Costs{!showInfo && <span style={{ color: "red" }}>*</span>}</label>
                            </span>
                        </div>

                        <div className={`field col-4 mt-3`}>
                            <span className="p-float-label">
                                <Calendar id="billingMonth" value={date} onChange={(e: any) => setDate(e.value)}
                                    disabled={showInfo}
                                    view="month" dateFormat="MM-yy" minDate={minDate}
                                />
                                <label htmlFor="billingMonth">Billing Month{!showInfo && <span style={{ color: "red" }}>*</span>}</label>
                            </span>
                        </div>

                        <div className={`field col-4 mt-3`}>
                            <span className="p-float-label">
                                <AutoComplete
                                    className={`auto-complete animate-block field`}
                                    itemTemplate={costCenterTemplate}
                                    value={ccSelectedValue}
                                    field="costCenter"
                                    suggestions={ccFilteredValues}
                                    completeMethod={ccSearchValues}
                                    onChange={(e) => {
                                        if (e.value === null) {
                                            e.value = ''
                                        }
                                        setCcSelectedValue(e.value)
                                        if (e !== undefined) {
                                            setCostCenterData({
                                                costCenterId: e.value.costCenterId,
                                                costCenter: e.value.costCenter,
                                            });
                                        } else {
                                            setCostCenterData(undefined);
                                        }
                                    }}
                                    id='source-cost-center-auto'
                                    disabled={showInfo}
                                    inputId='costCenter'
                                    dropdown
                                />
                                <label htmlFor="source-cost-center-auto">Source Cost Center{!showInfo && <span style={{ color: "red" }}>*</span>}</label>
                            </span>
                        </div>

                        <div className={`field col-4 mt-3`}>
                            <span className="p-float-label">
                                <AutoComplete
                                    className={`auto-complete animate-block field`}
                                    itemTemplate={costCenterTemplate}
                                    value={ccSelectedTargetValue}
                                    field="costCenter"
                                    forceSelection
                                    suggestions={ccFilteredValues}
                                    completeMethod={ccSearchValues}
                                    onChange={(e) => {
                                        if (e.value === null) {
                                            e.value = ''
                                        }
                                        console.log(e);

                                        setCcSelectedTargetValue(e.value)
                                        if (e !== undefined) {
                                            setTargetCostCenterData({
                                                costCenterId: e.value.costCenterId,
                                                costCenter: e.value.costCenter,
                                            });
                                        } else {
                                            setTargetCostCenterData(undefined);
                                        }
                                    }}
                                    id='target-cost-center-auto'
                                    disabled={showInfo}
                                    inputId='costCenter'
                                    dropdown
                                />
                                <label htmlFor="target-cost-center-auto">Receiving Cost Center{!showInfo && <span style={{ color: "red" }}>*</span>}</label>
                            </span>
                        </div>

                        <div className={`field col-12`}>
                            <span className="p-float-label">
                                <InputTextarea
                                    id="additionalInfo"
                                    disabled={showInfo}
                                    name="additionalInfo"
                                    value={additionalInfo}
                                    rows={2}
                                    onChange={(e) => {
                                        setAdditionalInfo(e.target.value);
                                    }}
                                />
                                <label htmlFor="additionalInfo">Additional Information</label>
                            </span>
                        </div>

                        <div className={`field col-4 mt-3`}>
                            <span className="p-float-label">
                                <InputText
                                    id="said"
                                    disabled={showInfo}
                                    name="said"
                                    value={SAID}
                                    onChange={(item) => {
                                        setSAID(item.target.value);
                                    }}
                                />
                                <label htmlFor="said">SA ID</label>
                            </span>
                        </div>

                        <div className={`field col-4 mt-3`}>
                            <span className="p-float-label">
                                <InputText
                                    id="CONumber"
                                    disabled={showInfo}
                                    name="CONumber"
                                    value={CONumber}
                                    onChange={(item) => {
                                        const number = item.target.value;
                                        if (/^\d{0,8}$/.test(`${number}`)) {
                                            setCONumber(number)
                                        } else {
                                            item.preventDefault();
                                        }
                                    }}
                                />
                                <label htmlFor="CONumber">CO Number</label>
                            </span>
                        </div>

                        {showDetails && editData &&
                            <>
                                <div className={`field col-4 mt-3`}>
                                    <span className="p-float-label">
                                        <InputText
                                            id="icaStatus"
                                            name="icaStatus"
                                            value={editData.icaStatus === '' ? 'Planned' : editData.icaStatus}
                                            disabled
                                        />
                                        <label htmlFor="icaStatus">ICA Status</label>
                                    </span>
                                </div>

                                <div className={`field col-4 mt-3`}>
                                    <span className="p-float-label">
                                        <InputText
                                            id="uploadedByNTID"
                                            name="uploadedByNTID"
                                            value={editData.uploadedByNTID}
                                            disabled
                                        />
                                        <label htmlFor="uploadedByNTID">Uploaded By</label>
                                    </span>
                                </div>

                                <div className={`field col-4 mt-3`}>
                                    <span className="p-float-label">
                                        <InputText
                                            id="uploadedOn"
                                            name="uploadedOn"
                                            value={editData.uploadedOn !== '' ? formatDateDDMMYYYY(editData.uploadedOn) : ''}
                                            disabled
                                        />
                                        <label htmlFor="uploadedOn">Uploaded On</label>
                                    </span>
                                </div>

                                <div className={`field col-4 mt-3`}>
                                    <span className="p-float-label">
                                        <InputText
                                            id="updatedByNTID"
                                            name="updatedByNTID"
                                            value={editData.updatedByNTID}
                                            disabled
                                        />
                                        <label htmlFor="updatedByNTID">Modified By</label>
                                    </span>
                                </div>

                                <div className={`field col-4 mt-3`}>
                                    <span className="p-float-label">
                                        <InputText
                                            id="updatededOn"
                                            name="updatededOn"
                                            // value={editData.updatededOn !== '' ? `${getFullMonth(new Date(editData.updatededOn))}-${new Date(editData.updatededOn).getFullYear()}` : ''}
                                            value={editData.updatededOn !== '' ? formatDateDDMMYYYY(editData.updatededOn) : ''}
                                            disabled
                                        />
                                        <label htmlFor="updatededOn">Modified On</label>
                                    </span>
                                </div>
                            </>
                        }

                        {
                            !showDetails && (
                                editData ?

                                    <div className={`field col-4 mt-3`}>
                                        <Button
                                            label="Update Cross Charge"
                                            onClick={modifyCrossCharge}
                                            icon="pi pi-save"
                                            className="p-button-sm"
                                            loading={isUpdatingCrossCharge}
                                            disabled={disableUpdate()}
                                        />
                                    </div>

                                    :

                                    <div className={`field col-4 mt-3`}>
                                        <Button
                                            label="Add Cross Charge"
                                            onClick={createCrossCharge}
                                            icon="pi pi-save"
                                            className="p-button-sm"
                                            loading={isAddingNewCrossCharge}
                                            disabled={!serviceName || !service || !date || !costs || !costCenterData || !targetCostCenterData}
                                        />
                                    </div>
                            )
                        }
                    </div>
                </div>

            </Dialog>
        </>
    )
}

export default AddOrEditCrossCharge;