import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '.';
import InvalidUser from './../views/invalid-user/index';

const initialState = {
	filteredCostCenters: [] as any[],
	filters: {
		assetSummary: {
			year: new Date().getFullYear(),
			active: '',
		},
	},
	refetchServices: false,
	isAdmin: false,
	isInvalidUser: false,
	isCostController: false,
	isServiceOwner: false,
	is103User: false,
	searchTerm: '',
	costcenterId: '',
	logoutUser: false,
	mailers: {
		serviceResponsible: false,
		allUsers: false,
		icaReport: false,
		ittResponsible: false,
		bugReportMailer: false,
		minimizeBugReport: false,
		minimizeData: {} as any,
	},
	sidebar: true,
	maintenanceMode: false,
	clusterServices: false,
	token: '',
	currentUser: null,
	devToken: {
		domain: '',
		ntid: '',
		timestamp: '',
		token: '',
		appversion: '',
	},
	homeFilters: {
		filterActive: false,
		costCenterFilter: { name: '', target_costcenter: '' },
		targetResponsilbeFilter: { user_responsible: '', target_responsible: '' },
		ccMasterFilter : { ccMaster: '' },
		ownCostCenter: false,
		viewType: 'list',
		sort: {
			field: '',
			order: '',
		},
		sortServices: {
			field: '',
			order: '',
		},
	},
	serviceOptions: {
		serviceDetails: false,
		reAssignDialog: false,
		bulkReassignment: false,
		picasDetails: false,
		releaseNotes: false,
		bulkEdit: false,
		showComments: false,
		showBaseFeeDialog: false,
	},
	modals: {
		common: {
			correctionApproval: false,
			serviceDifference: false,
			imageUpdater: false,
			assignDeputy: false,
			timeline: false,
		},
		settings: {
			addMapping: false,
			addCostCenter: false,
			editUser: false,
			editMapping: false,
			clusteringKeywords: false,
			costCenterUpload: false,
			newCostCenterUsers: false,
			addServiceID: false,
			ccMasterUpload: false,
		},
		dataProcessing: {
			uploadChargingReport: false,
			uploadAzureChargingReport: false,
			uploadGemsList: false,
			uploadAssetPriceList: false,
			addCrossCharge: false,
		},
		assetList: {
			assetPriceConfiguration: false,
			showInvalidAsset: false,
		},
		icaExport: {
			slstArt: false,
			saConfiguration: false,
		},
	},
	data: {
		editUserDate: {},
		servicesToUpdate: [] as any[],
	},
};

export const commonSlice = createSlice({
	name: 'common',
	initialState,
	// Redux Toolkit allows us to write "mutating" logic in reducers. It
	// doesn't actually mutate the state because it uses the Immer library,
	// which detects changes to a "draft state" and produces a brand new
	// immutable state based off those changes
	reducers: {
		setIsAdmin: (state, action) => {
			state.isAdmin = action.payload;
		},
		setInvalidUser: (state, action) => {
			state.isInvalidUser = action.payload;
		},
		setIsCostController: (state, action) => {
			state.isCostController = action.payload;
		},
		setIsServiceOwner: (state, action) => {
			state.isServiceOwner = action.payload;
		},
		setIs103User: (state, action) => {
			state.is103User = action.payload;
		},
		logoutUser: (state) => {
			state.logoutUser = true;
		},
		setToken: (state, action) => {
			state.token = action.payload;
		},
		setMaintenanceMode: (state, action) => {
			state.maintenanceMode = action.payload;
		},
		setSorting: (state, action) => {
			state.homeFilters = { ...state.homeFilters, ...action.payload };
		},
		setOwnCostCenter: (state, action) => {
			state.homeFilters = { ...state.homeFilters, ...action.payload };
		},
		setCurrentUser: (state, action) => {
			state.currentUser = action.payload;
		},
		setDevToken: (state, action) => {
			state.devToken = action.payload;
		},
		setCluster: (state) => {
			state.clusterServices = !state.clusterServices;
		},
		setSearchTerm: (state, action) => {
			state.searchTerm = action.payload;
		},
		setMailers: (state, action) => {
			state.mailers = {
				...state.mailers,
				...action.payload,
			};
		},
		toggleSidebar: (state) => {
			state.sidebar = !state.sidebar;
		},
		setSettingsModal: (state, action) => {
			state.modals.settings = {
				...state.modals.settings,
				...action.payload,
			};
		},
		setAssetListModal: (state, action) => {
			state.modals.assetList = { ...state.modals.assetList, ...action.payload };
		},
		setCommonModal: (state, action) => {
			state.modals.common = {
				...state.modals.common,
				...action.payload,
			};
		},
		setDataProcessingModal: (state, action) => {
			state.modals.dataProcessing = {
				...state.modals.dataProcessing,
				...action.payload,
			};
		},
		setRefetchServices: (state) => {
			state.refetchServices = !state.refetchServices;
		},
		setCostCenterFilter: (state, action) => {
			state.homeFilters = { ...state.homeFilters, ...action.payload };
		},
		setServiceOptions: (state, action) => {
			state.serviceOptions = { ...state.serviceOptions, ...action.payload };
		},
		setEditUserData: (state, action) => {
			state.data.editUserDate = { ...state.data, ...action.payload };
		},
		setServicesToUpdate: (state, action) => {
			console.log("Passed Value :: ", action)
			console.log("Passed State :: ", state.data.servicesToUpdate)
			if (action.payload.key === 'CLEAR') {
				state.data.servicesToUpdate = [];
			} else if (action.payload.key === 'REMOVE') {
				state.data.servicesToUpdate = [
					...state.data.servicesToUpdate.filter((_item) => {
						if (
							_item.serviceId === action.payload.value.serviceId &&
							_item.serviceName === action.payload.value.serviceName &&
							_item.said === action.payload.value.said &&
							_item.additionalInformation ===
								action.payload.value.additionalInfo
						)
							return false;
						else return true;
					}),
				];
			} else {
				const { key, value } = action.payload;
				const idx = state.data.servicesToUpdate.findIndex(
					(_item) =>
						_item.serviceId === key.serviceId &&
						_item.serviceName === key.serviceName &&
						_item.said === key.said &&
						_item.additionalInformation === key.additionalInfo
				);

				if (idx === -1) {
					state.data.servicesToUpdate = [
						...state.data.servicesToUpdate,
						{
							serviceId: action.payload.key.serviceId,
							serviceName: action.payload.key.serviceName,
							said: action.payload.key.said,
							additionalInformation: action.payload.key.additionalInfo,
							additionalInfo : action.payload.key.additionalInfo,
							ioNumber: action.payload.key.ioNumber,
							responsible: action.payload.key.responsible,
							planned: action.payload.value.reduce(
								(acc: number, service: any) => (acc += service.plannedAmount),
								0
							),
							booked: action.payload.value.reduce(
								(acc: number, service: any) => (acc += service.bookedAmount),
								0
							),
							months: action.payload.value.map(
								(_item: any) => _item.serviceMonth
							),
						},
					];
				} else {
					if (action.payload.value.length === 0) {
						state.data.servicesToUpdate = [
							...state.data.servicesToUpdate.filter((_item) => {
								if (
									_item.serviceId === key.serviceId &&
									_item.serviceName === key.serviceName &&
									_item.said === key.said &&
									_item.additionalInformation === key.additionalInfo
								)
									return false;
								else return true;
							}),
						];
					} else {
						state.data.servicesToUpdate = [
							...state.data.servicesToUpdate.map((_item) => {
								if (
									_item.serviceId === key.serviceId &&
									_item.serviceName === key.serviceName &&
									_item.said === key.said &&
									_item.additionalInformation === key.additionalInfo
								) {
									return {
										..._item,
										additionalInformation : _item.additionalInformation,
										additionalInfo: _item.additionalInformation,
										planned: action.payload.value.reduce(
											(acc: number, service: any) =>
												(acc += service.plannedAmount),
											0
										),
										booked: action.payload.value.reduce(
											(acc: number, service: any) =>
												(acc += service.bookedAmount),
											0
										),
										months: action.payload.value.map(
											(_item: any) => _item.serviceMonth
										),
									};
								} else {
									return {..._item,
										additionalInfo: _item.additionalInformation};
								}
							}),
						];
					}
				}
			}
		},
		setICAExportModals: (state, action) => {
			state.modals.icaExport = { ...state.modals.icaExport, ...action.payload };
		},
		setFilteredCostCenters: (state, action) => {
			state.filteredCostCenters = [...action.payload];
		},
		setAssetSummaryFilters: (state, action) => {
			state.filters.assetSummary = {
				...state.filters.assetSummary,
				...action.payload,
			};
		},
		setCostcenterId: (state, action) => {
			state.costcenterId = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	setSorting,
	setFilteredCostCenters,
	setOwnCostCenter,
	setCluster,
	setMailers,
	setSearchTerm,
	toggleSidebar,
	setCostCenterFilter,
	setSettingsModal,
	setDataProcessingModal,
	setAssetListModal,
	setServiceOptions,
	setEditUserData,
	setCommonModal,
	setToken,
	setDevToken,
	setCurrentUser,
	setAssetSummaryFilters,
	setICAExportModals,
	setIsAdmin,
	setInvalidUser,
	setIsCostController,
	setIsServiceOwner,
	setMaintenanceMode,
	setRefetchServices,
	setServicesToUpdate,
	setCostcenterId,
	setIs103User,
} = commonSlice.actions;

export const commonState = (state: RootState) => state.common;
export const getSearchTerm = (state: RootState) => state.common.searchTerm;
export const getCostcenterId = (state: RootState) => state.common.costcenterId;
export const selectSidebar = (state: RootState) => state.common.sidebar;
export const selectHomeFilters = (state: RootState) => state.common.homeFilters;
export const getToken = (state: RootState) => state.common.token;
export const getCommonModal = (state: RootState) => state.common.modals.common;
export const settingsModalState = (state: RootState) =>
	state.common.modals.settings;
export const getICAExportModalSettings = (state: RootState) =>
	state.common.modals.icaExport;
export const dataProcessingModalState = (state: RootState) =>
	state.common.modals.dataProcessing;
export const getAssetListModalState = (state: RootState) =>
	state.common.modals.assetList;
export const getServiceOptions = (state: RootState) =>
	state.common.serviceOptions;
export const getEditUserData = (state: RootState) =>
	state.common.data.editUserDate;
export const getServicesToUpdate = (state: RootState) =>
	state.common.data.servicesToUpdate;
export const getMailers = (state: RootState) => state.common.mailers;
export default commonSlice.reducer;
export const getAssetSummaryFilter = (state: RootState) =>
	state.common.filters.assetSummary;
export const getMaintenanceMode = (state: RootState) =>
	state.common.maintenanceMode;
export const getIsAdmin = (state: RootState) => state.common.isAdmin;
export const getInvalidUser = (state: RootState) => state.common.isInvalidUser;
export const getIsCostController = (state: RootState) => state.common.isCostController;
export const getIsServiceOwner = (state: RootState) => state.common.isServiceOwner;
export const getIs103User = (state: RootState) => state.common.is103User;
export const getRefetchServices = (state: RootState) =>
	state.common.refetchServices;
