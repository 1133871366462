import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import DisplayIcon from '../icon';
import { useDispatch, useSelector } from 'react-redux';
import {
	getServiceOptions,
	setRefetchServices,
	setServiceOptions,
} from '../../store/commonSlice';
import PopOver from '../../views/service/popover';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import useUpdateIO from '../../hooks/useUpdateIO';
import {
	ICostCenterServiceData,
	IHistoryResponse,
	IService,
} from '../../types/service.interfaces';
import { ICostCenterData } from '../../views/cost-center';
import CommentsDialog from '../../views/service/comments-dialog';
import { useGetAllHistoryMutation } from '../../store/servicesApi';
import {
	checkAzureService,
	formatCurrency,
	formatDateDDMMYYYY,
	searchHighlighter,
} from '../../utils/utility-function';
import ReactTooltip from 'react-tooltip';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import useGetOU from '../../hooks/useGetOU';

const ServiceCard = ({
	service,
	costCenter,
	searchTerm,
	isArchive,
	isFetchingData,
}: {
	service: ICostCenterServiceData;
	costCenter: ICostCenterData;
	searchTerm?: string;
	isArchive: boolean;
	isFetchingData?: boolean;
}) => {
	const [history, setHistory] = useState<IHistoryResponse[]>();
	const [editIO, setEditIO] = useState(false);
	const [showComments, setShowComments] = useState(false);
	const [IO, setIO] = useState(service.ioNumber);
	const dispatch = useDispatch();
	const { updateIONumber, isLoading } = useUpdateIO();
	const [isPopoverOpen, setIsPopoverOpen] = useState(-1);
	const showCommentsModal = useSelector(getServiceOptions).showComments;
	const [getHistory, { isLoading: isGettingHistory }] =
		useGetAllHistoryMutation();
	
	const ou = useGetOU();

	const header = () => {
		return (
			<div className="flex align-items-center justify-content-between">
				<h5>Cost Center History</h5>
			</div>
		);
	};

	const openInNewTab = (url: string) => {
		window.open(url, '_blank', 'noreferrer');
	};

	const historyTable = () => {
		return (
			<div className="cards history-table">
				<DataTable
					value={history}
					header={header}
					responsiveLayout="scroll"
					rowHover
					onClick={(e) => e.stopPropagation()}
				>
					<Column
						header="#"
						alignHeader="left"
						body={(data, props) => props.rowIndex + 1}
					></Column>
					<Column
						field="updatedOn"
						header="Updated On"
						body={(data: IHistoryResponse) =>
							formatDateDDMMYYYY(data.updatedOn)
						}
					></Column>
					<Column field="updatedBy.firstName" header="Updated By"></Column>
					<Column field="oldCostcenter" header="Old Cost Center"></Column>
					<Column field="updatedCc" header="New Cost Center"></Column>
					<Column field="names" header="Months"></Column>
				</DataTable>
			</div>
		);
	};

	return (
		<>
			{service.serviceName !== 'Picas Report' ? (
				<div className="flex i-service align-items-center justify-content-center flex-column mb-4 transition-all transition-linear transition-duration-300 ">
					{isFetchingData ? (
						<div className="w-full flex justify-content-start align-items-center">
							<ProgressSpinner style={{ width: 25, height: 25, margin: 0 }} />
							<div>
								<h6 className="ml-5">
									Fetching Additional Details From Server ...
								</h6>
							</div>
						</div>
					) : (
						<>
							<div className="flex  align-items-center justify-content-between mb-4 w-full">
								<h4
									dangerouslySetInnerHTML={searchHighlighter(
										service.serviceName,
										searchTerm
									)}
								></h4>

								<h6
									style={{ marginLeft: '2rem' }}
									dangerouslySetInnerHTML={searchHighlighter(
										service.serviceId,
										searchTerm
									)}
								></h6>
							</div>

							<div className="flex justify-content-between w-full">
								<div className="flex align-items-center justify-content-between">
									<DisplayIcon icon="booked" className="service-icon" />

									<div className="flex flex-column ml-1">
										<span className="booked">Booked</span>
										<h3 className="booked">
											{service.bookedAmount &&
												service.bookedAmount.toLocaleString('de-DE')}{' '}
											€
										</h3>
									</div>
								</div>
								<div
									className="flex align-items-center justify-content-between"
									style={{
										borderLeft: '1px solid #e7e9f0',
										paddingLeft: '1em',
										marginLeft: '1rem',
									}}
								>
									<DisplayIcon icon="planned" className="card-icon" />

									<div className=" flex flex-column ml-1">
										<span className="planned">Planned</span>
										<h3 className="planned">
											{service.plannedAmount &&
												service.plannedAmount.toLocaleString('de-DE')}{' '}
											€
										</h3>
									</div>
								</div>
								<div
									className="flex align-items-center justify-content-between"
									style={{
										borderLeft: '1px solid #e7e9f0',
										paddingLeft: '1rem',
										marginLeft: '1rem',
									}}
								>
									{!editIO ? (
										<DisplayIcon icon="ticket" className="card-icon" />
									) : null}

									<div
										className="flex flex-column ml-1"
										onClick={(e) => e.stopPropagation()}
									>
										{!editIO ? (
											<>
												<span>IO #</span>
												<h3>
													{service.ioNumber && service.ioNumber.length > 0
														? service.ioNumber
														: '-'}
												</h3>
											</>
										) : (
											<span className="p-float-label">
												<InputText
													name="ionumber"
													value={IO}
													onChange={(e) => setIO(e.target.value)}
												/>
												<label htmlFor="ionumber">IO #</label>
											</span>
										)}
									</div>
								</div>
								{isLoading ? (
									<div className="flex justify-content-between align-items-center transition-all transition-linear transition-duration-300">
										<ProgressSpinner
											style={{ width: 25, height: 25, margin: 0 }}
										/>
									</div>
								) : (
									<div className="flex justify-content-between align-items-center transition-all transition-linear transition-duration-300">
										{!editIO ? (
											!isArchive ? (
												<Button
													className="p-button-rounded p-button-text p-button-icon-only"
													tooltip="Edit IO Number"
													tooltipOptions={{ position: 'top' }}
													onClick={(e: any) => {
														e.stopPropagation();
														setEditIO(true);
													}}
												>
													<DisplayIcon className="rounded-button" icon="edit" />
												</Button>
											) : null
										) : (
											<>
												<Button
													className="p-button-rounded p-button-text p-button-icon-only"
													tooltip="Revert Changes"
													tooltipOptions={{ position: 'top' }}
													onClick={(e: any) => {
														e.stopPropagation();
														setEditIO(false);
													}}
												>
													<DisplayIcon
														className="rounded-button mr-1"
														icon="reset"
													/>
												</Button>
												{IO !== service.ioNumber ? (
													<Button
														className="p-button-rounded p-button-text p-button-icon-only"
														tooltip="Save IO Number"
														tooltipOptions={{ position: 'top' }}
														onClick={(e: any) => {
															e.stopPropagation();
															updateIONumber({
																cc_Id: costCenter.id,
																serviceList: service,
																ioNumber: IO,
																ou
															});
															setIO('');
															setEditIO(false);
														}}
													>
														<DisplayIcon
															className="rounded-button"
															icon="tablerSave"
														/>
													</Button>
												) : (
													<DisplayIcon
														className="rounded-button mr-1"
														icon="dismiss"
														data-tip="Cancel IO Edit Mode"
														onClick={(e: any) => {
															e.stopPropagation();
															setEditIO(false);
														}}
													/>
												)}{' '}
											</>
										)}
										{!editIO && (
											<div className="flex">
												<Button
													className="p-button-rounded p-button-text p-button-icon-only"
													tooltip="Display Service Comments & ILV Comments"
													tooltipOptions={{ position: 'top' }}
													onClick={(e: any) => {
														e.stopPropagation();
														setShowComments(true);
														dispatch(setServiceOptions({ showComments: true }));
													}}
												>
													<DisplayIcon
														icon="comment"
														className={`rounded-button ${service.isComment || service.isILVComment
															? 'rounded-button__highlight'
															: ''
															}`}
													/>
												</Button>

												{service.isHistory && (
													<Button
														className="p-button-rounded p-button-text p-button-icon-only"
														tooltip="Display Cost Center Change History"
														tooltipOptions={{ position: 'top' }}
														onClick={async (e: any) => {
															e.stopPropagation();
															let history = await getHistory({
																cc_Id: costCenter.id,
																comment: 'null',
																said: service.said,
																serviceId: service.serviceId,
																serviceName: service.serviceName,
																additionalInformation:
																	checkAzureService(service),
															}).unwrap();
															setHistory(history);
															setIsPopoverOpen(service.id_pk);
														}}
													>
														<DisplayIcon
															icon="history"
															className="rounded-button mr-1"
														/>
													</Button>
												)}

												{isPopoverOpen === service.id_pk && (
													<PopOver
														callback={() => {
															setIsPopoverOpen(-1);
														}}
													>
														{historyTable}
													</PopOver>
												)}
											</div>
										)}	
										{!isArchive && !editIO && service.serviceName.includes("ServiceNow") && (
											<Button
												className="p-button-rounded p-button-text p-button-icon-only"
												tooltip="ServiceNow info"
												tooltipOptions={{ position: 'top' }}
												onClick={(e: any) => {
													e.stopPropagation();
													openInNewTab('https://servicenow.i.mercedes-benz.com/esc?id=kb_article&table=kb_knowledge&sys_kb_id=8ac9e871dbede91076c6000cd39619f9&spa=1')
												}}
											>
												<DisplayIcon className="rounded-button" icon="serviceInfo" />
											</Button>
										)}
									</div>
								)}
							</div>
							<div className='flex w-full justify-content-between'>
								<div className="flex align-items-center mt-4 w-9">
									<DisplayIcon icon="info" className="info-icon" />
									<div className="flex align-items-center ">
										<h4
											className="info"
											dangerouslySetInnerHTML={searchHighlighter(
												service.additionalInfo &&
													service.additionalInfo.length > 0
													? service.additionalInfo
													: '-',
												searchTerm
											)}
										></h4>
									</div>
								</div>
								{/* cc-918: hide forecast */}
								<div className="flex align-items-center justify-content-end mt-4 w-3">
								<DisplayIcon icon="forecast" className="info-icon" />
								<div className="flex flex-column justify-content-end">
								<h4 className="info-bold">
										Forecast
									</h4>
									<h4 className="info-bold">
										{' '}
										{service.yearlyTotal &&
											service.yearlyTotal.toLocaleString('de-DE')}{' '}
										€
									</h4>

								</div>
							</div>

							</div>
						</>
					)}
				</div>
			) : (
				<div className="flex i-service align-items-center flex-column transition-all transition-linear transition-duration-300 picas-card">
					<div className="flex  align-items-center justify-content-between w-full">
						<h4
							dangerouslySetInnerHTML={searchHighlighter(
								service.serviceName,
								searchTerm
							)}
						></h4>
						<div className="flex align-items-center justify-content-between">
							<DisplayIcon icon="picas" className="service-icon" />
							<div className="flex flex-column ml-1">
								<span className="booked">Picas</span>
								<h3 className="booked">
									{service.bookedAmount && formatCurrency(service.bookedAmount)}
								</h3>
							</div>
						</div>
						<Button
							className="p-button-rounded p-button-text p-button-icon-only"
							tooltip="Display Picas Comments"
							tooltipOptions={{ position: 'top' }}
							onClick={(e: any) => {
								e.stopPropagation();
								setShowComments(true);
								dispatch(setServiceOptions({ showComments: true }));
							}}
						>
							<DisplayIcon
								icon="comment"
								className={`rounded-button mr-1 ${service.isComment ? 'rounded-button__highlight' : ''
									}`}
							/>
						</Button>
					</div>
				</div>
			)}

			{showComments && (
				<CommentsDialog
					costCenter={costCenter}
					service={service}
					isArchive={isArchive}
					callback={() => setShowComments(false)}
				/>
			)}
		</>
	);
};

export default ServiceCard;
