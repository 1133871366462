import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import InputField from '../../components/input-field/input-field';
import useFindCurrentLocation from '../../hooks/useFindCurrentLocation';
import useGetOU from '../../hooks/useGetOU';
import {
	useGetNewServicesQuery,
	useGetWildCardMappingQuery,
} from '../../store/chargingReportApi';
import { useMapNewServicesMutation } from '../../store/servicesApi';
import { ChargingReportHeader } from '../charging-report-upload';

const NewServices = () => {

	const ou = useGetOU();
	useEffect(() => {
		document.title = 'New Services | XCharge';
	}, []);
	const { data, isLoading, isSuccess, isError, error } =
		useGetNewServicesQuery({ou});
	const [selectedServices, setSelectedServices] = useState<any>();
	const currentPage = useFindCurrentLocation();
	const [mapServiceDialog, setMapServiceDialog] = useState(false);
	const [mapNewServices, { isLoading: isMappgingServices }] =
		useMapNewServicesMutation();
	const mapServices = () => {};
	const formik = useFormik({
		initialValues: {
			costCenterId: undefined,
			keyWord: '',
			target_responsible: '',
		},
		onSubmit: (data) => {
			formik.resetForm();
		},
	});
	const deleteFooter = () => {
		return (
			<div>
				<Button
					label="Cancel"
					icon="pi pi-times"
					onClick={() => {
						setMapServiceDialog(false);
					}}
					className="p-button-text"
				/>
				<Button
					label="Map Services"
					icon="pi pi-plus"
					disabled={!Number.isInteger(Number(formik.values.costCenterId))}
					onClick={async () => {
						console.log(formik.values);
						await mapNewServices({
							costCenterId: formik.values.costCenterId
								? formik.values.costCenterId
								: 0,
							isMapped: false,
							keyWord: formik.values.keyWord ? formik.values.keyWord : '',
							serviceId: selectedServices
								? selectedServices.map((_service: any) => _service.id)
								: [0],
						})
							.unwrap()
							.then((payload: any) =>
								toast.success('Services Mapped Successfully')
							)
							.catch((e: any) => toast.error('Error Mapping Services'));
						setMapServiceDialog(false);
					}}
				/>
			</div>
		);
	};
	return (
		<>
			<div className={`grid`}>
				<div className="cards">
					<DataTable
						value={data}
						responsiveLayout="scroll"
						className='new-services'
						onRowClick={() => {}}
						header={
							<ChargingReportHeader
								title="New Services Found"
								currentPage={currentPage}
								callback={() => {
									setMapServiceDialog(true);
								}}
								options={{
									disableButton: selectedServices
										? selectedServices.length === 0
										: true,
								}}
							/>
						}
						dataKey="id"
						rowHover
						selection={selectedServices}
						onSelectionChange={(e) => setSelectedServices(e.value)}
					>
						<Column
							selectionMode="multiple"
							headerStyle={{ width: '3em' }}
						></Column>
						<Column
							header="#"
							alignHeader="left"
							body={(data, props) => {
								ReactTooltip.rebuild();
								return props.rowIndex + 1;
							}}
						></Column>
						<Column field="said" header="SAID" sortable></Column>
						<Column field="serviceId" header="ID" sortable></Column>
						<Column field="serviceName" header="Service Name" sortable></Column>
						<Column
							field="aditionalInfo"
							header="Additional Information"
							sortable
						></Column>
					</DataTable>
				</div>
			</div>
			<Dialog
				header="Map Service To Cost Center"
				visible={mapServiceDialog}
				onHide={() => {
					setMapServiceDialog(false);
				}}
				footer={deleteFooter}
			>
				<div className="p-fluid grid formgrid mt-5">
					<div className="grid col-12">
						{' '}
						<InputField
							formik={formik}
							type="autocomplete-ccname"
							field="costCenterId"
							callback={(o) => {
								console.log(o);
								if (o !== undefined) {
									formik.setFieldValue('costCenterId', o.id);
									formik.setFieldValue(
										'target_responsible',
										o.target_responsible
									);
								} else {
									console.log('executed :(');
									formik.setFieldValue('costCenterId', undefined);
									formik.setFieldValue('target_responsible', '');
								}
							}}
							colSpan={6}
						/>
						<InputField
							field="target_responsible"
							colSpan={6}
							formik={formik}
							disabled
							label="Target Responsible"
						/>
						<div className={`field col-12 mt-3`}>
							<span className="p-float-label">
								<InputTextarea
									id="mapservice-comment"
									name="keyWord"
									value={formik.values.keyWord}
									onChange={formik.handleChange}
									autoResize
								/>
								<label htmlFor="mapservice-comment">Keyword</label>
							</span>
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default NewServices;
