import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../hooks';

import Navigation from '../../components/navigation';

import { getAllCostCenter, allCostCenters } from '../../store/actualSlice';
import { selectSidebar, getInvalidUser } from '../../store/commonSlice';
import CostCenterCard from '../../components/cost-center-card';
import { useDispatch, useSelector } from 'react-redux';

import { useGetAllActiveCostCentersQuery } from '../../store/apiSlice';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useGetOU from '../../hooks/useGetOU';

const HomePage: React.FC = function () {
	//const allCostCenters = useAppSelector((state) => state.actual.actualAll);
	const sidebar = useSelector(selectSidebar);
	const dispatch = useAppDispatch();
	const ou = useGetOU();
	const {
		data = [],
		isLoading,
		isSuccess,
		isError,
		error,
	} = useGetAllActiveCostCentersQuery({ou});

	const isInvalidUser = useSelector(getInvalidUser);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		isInvalidUser && navigate('/invalidUser', { state: { prevPath: location.pathname } })
	}, [isInvalidUser])

	return (
		<>
			<Outlet />
		</>
	);
};

export default HomePage;
