import { useEffect, useMemo, useRef, useState } from 'react';

import { Button } from 'primereact/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
	useGetAllServicesQuery,
	useGetAllCostCentersQuery,
	useGetClusterKeywordsQuery,
	useLazyGetAllServicesQuery,
} from '../../store/apiSlice';

import { IProp, IService } from '../../components/cost-center-card';
import './style.scss';
import DisplayIcon from '../../components/icon';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import ExtraActions from '../../components/extra-actions';
import ReactTooltip from 'react-tooltip';
import {
	commonState,
	getIsAdmin,
	getIsCostController,
	getRefetchServices,
	getServiceOptions,
	selectHomeFilters,
	setServiceOptions,
} from '../../store/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import ServiceCard from '../../components/service-card';
import { usePopper } from 'react-popper';
import PopOver from './popover';
import PicasDialog from './picas-dialog';
import useUpdateIO from '../../hooks/useUpdateIO';
import ReassignDialog from './reassign-dialog';
import ServiceDetailsDialog from './service-details-dialog';
import {
	IHistoryResponse,
	IServiceDetails,
	IServiceMonthlyData,
} from '../../types/service.interfaces';
import {
	useGetAllHistoryMutation,
	useGetAllMonthlyServiceDataMutation,
} from '../../store/servicesApi';
import useFindCurrentLocation from '../../hooks/useFindCurrentLocation';
import {
	checkAzureService,
	dualSearch,
	formatCurrency,
	formatDateDDMMYYYY,
	searchHighlighter,
	sortingFunction,
} from '../../utils/utility-function';
import {
	useGetAllArchivedServicesQuery,
	useGetAllMonthlyServiceDataArchiveMutation,
	useLazyGetAllArchivedServicesQuery,
} from '../../store/archiveApi';
import PAGES from '../../utils/pages.enum';
import CommentsDialog from './comments-dialog';
import { useGetCurrentUserQuery, useIsUserDeputyQuery } from '../../store/UserApi';
import CardSkeleton from '../../components/card-skeleton';
import { ProgressSpinner } from 'primereact/progressspinner';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Message } from 'primereact/message';
import { ScrollTop } from 'primereact/scrolltop';
import { useGetPicasDetailsQuery } from '../../store/picasApi';
import React from 'react';
import { RadioButton } from 'primereact/radiobutton';
import BulkReassignDialog from './bulk-reassign-dialog';
import useGetOU from '../../hooks/useGetOU';

export interface IServiceRequest {
	additionalInformation: string;
	cc_Id: number;
	comment: string;
	said: string;
	serviceId: string;
	serviceName: string;
	versionId: number;
	year: number;
	ou: number;
}

interface IData {
	serviceMonth: number;
	amount: number;
	month: string;
	plannedAmount: number;
	bookedAmount: number;
	icaId: number;
}

export interface IServiceData {
	id_pk: number;
	serviceId: string;
	serviceName: string;
	said: string;
	additionalInfo: string;
	responsible: string;
	plannedTotal: number;
	bookedTotal: number;
	ioNumber: string;
	data: IData[];
	year: number;
	years: number[];
	cc_Id: number;
}

const ServicePage: React.FC = function () {
	const [isArchive, setIsArchive] = useState(false);
	const [bulkIONumber, setBulkIONumber] = useState('');
	const [referenceElement, setReferenceElement] =
		useState<HTMLButtonElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
		null
	);
	const params = useParams();
	const dispatch = useDispatch();
	const location = useLocation();
	
	const ou = useGetOU();

	const [showComments, setShowComments] = useState(false);
	const reAssignDialog = useSelector(getServiceOptions).reAssignDialog;
	const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
	});

	const { updateIONumber, isLoading } = useUpdateIO();

	const [costCenter, setCostCenter] = useState<any>();
	const [service, setService] = useState<any>();
	const pageName = useFindCurrentLocation();
	const searchTerm = useSelector(commonState).searchTerm;
	const showDialog = useSelector(getServiceOptions).serviceDetails;
	const refetchData = useSelector(getRefetchServices);

	const timestampRef = useRef(Date.now()).current;

	const { data: currentUser, isLoading: isLoadingUser } =
		useGetCurrentUserQuery(0);

	// const isAdmin = currentUser?.role.isAdmin || currentUser?.role.isSuperAdmin;
	const isAdmin = useSelector(getIsAdmin);
	const [history, setHistory] = useState<IHistoryResponse[]>([]);
	const [getHistory, { isLoading: isGettingHistory }] =
		useGetAllHistoryMutation();

	const historyHeader = () => {
		return (
			<div className="flex align-items-center justify-content-between">
				<h5>Cost Center History</h5>
			</div>
		);
	};
	const openInNewTab = (url: string) => {
		window.open(url, '_blank', 'noreferrer');
	};
	const historyTable = () => (
		<div className="cards history-table">
			<DataTable
				value={history}
				header={historyHeader}
				responsiveLayout="scroll"
				rowHover
				onClick={(e) => e.stopPropagation()}
			>
				<Column
					header="#"
					alignHeader="left"
					body={(data, props) => props.rowIndex + 1}
				></Column>
				<Column
					field="updatedOn"
					header="Updated On"
					body={(data: IHistoryResponse) => formatDateDDMMYYYY(data.updatedOn)}
				></Column>
				<Column field="updatedBy.firstName" header="Updated By"></Column>
				<Column field="oldCostcenter" header="Old Cost Center"></Column>
				<Column field="updatedCc" header="New Cost Center"></Column>
				<Column field="names" header="Months"></Column>
			</DataTable>
		</div>
	);

	const [isPopoverOpen, setIsPopoverOpen] = useState(-1);
	const [currentS, setCurrentS] = useState<any>();
	const {
		data: serviceList = [],
		isLoading: isLoadingService,
		isSuccess: isSuccessServiceList,
		isError: isErrorServiceList,
		error: errorServiceList,
	} = useGetAllCostCentersQuery({ou});

	const [
		fetchArchivedServices,
		{ isFetching: isLoadingArchivedServices, data: _services },
	] = useLazyGetAllArchivedServicesQuery();

	const [
		fetchServices,
		{ isFetching: isLoadingServices, data: _archiveServices },
	] = useLazyGetAllServicesQuery();

	useEffect(() => {
		const getArchiveServices = async () => {
			if (pageName === PAGES.ARCHIVE_SERVICES) {
				if (params.costCenterId && params.versionId) {
					const { data } = await fetchArchivedServices(
						{
							costCenterId: Number(params.costCenterId),
							versionId: Number(params.versionId),
						},
						true
					);
					setPrimeServices(data);
				}
			} else {
				if (params.costCenterId) {
					const { data } = await fetchServices(
						{
							costCenterId: Number(params.costCenterId),
							ou
						},
						true
					);
					setPrimeServices(data);
				}
			}
		};
		console.log(':: PAGE NAME ::', pageName);
		if (pageName === PAGES.ARCHIVE_SERVICES) {
			document.title = `Archive Details | XCharge`;
			setIsArchive(true);
		}
		getArchiveServices();
	}, [_services, _archiveServices]);

	const { data: clusteringKeywords = [] } = useGetClusterKeywordsQuery();

	const clusterService = (services: any) => {
		console.log('SERVICES ::', services);
		const _services = JSON.parse(JSON.stringify(services));
		const clusteredServices: any = [];

		for (let service of _services) {
			let foundService = clusteredServices.find(
				(_service: any) =>
					_service.serviceId === service.serviceId &&
					_service.serviceName === service.serviceName
			);

			let excludeService = clusteringKeywords.filter((keyword: any) => {
				let cleanServiceName = service.serviceName.replace(/\u00a0/g, ' ');
				let cleanKeyword = keyword.sAs.replace(/\u00a0/g, ' ');
				let cleanAdditionalInfo = '';
				if (service.additionalInfo !== undefined) {
					cleanAdditionalInfo = service.additionalInfo.replace(/\u00a0/g, ' ');
				}
				return (
					cleanServiceName.includes(cleanKeyword) ||
					cleanAdditionalInfo.includes(cleanKeyword)
				);
			});

			if (!foundService || excludeService.length > 0) {
				clusteredServices.push(service);
			} else {
				console.log('Please Cluster');
				if (Array.isArray(foundService.clusterInfo)) {
					console.log('No Cluster Info', foundService);
					foundService.clusterInfo = [
						...foundService.clusterInfo,
						service.additionalInfo,
					];
					console.log('After Cluster Info', foundService);
					foundService.ioNumber =
						service.ioNumber.length === 0 ||
							foundService.ioNumber.length === 0 ||
							service.ioNumber !== foundService.ioNumber
							? ''
							: service.ioNumber;
				} else {
					console.log('No Cluster Info', foundService);
					foundService.clusterInfo = [
						foundService.additionalInfo,
						service.additionalInfo,
					];

					console.log('After Cluster Info', foundService);

					foundService.ioNumber =
						service.ioNumber.length === 0 ||
							foundService.ioNumber.length === 0 ||
							service.ioNumber !== foundService.ioNumber
							? ''
							: service.ioNumber;
				}
				foundService.additionalInfo = '# Clustered Service #';
				foundService.plannedAmount += service.plannedAmount;
				foundService.yearlyTotal = service.yearlyTotal;
				foundService.bookedAmount += service.bookedAmount;
			}
		}

		return clusteredServices;
	};
	const homeFilters = useSelector(selectHomeFilters);
	const allModals = useSelector(getServiceOptions);
	const [id, setId] = useState(-1);

	const costCenterId = params.costCenterId ? Number(params.costCenterId) : 0;

	const isClusterEnabled = useSelector(commonState).clusterServices;
	const [selectedServices, setSelectedServices] = useState<IService[]>();
	const [editingRows, setEditingRows] = useState({});

	const { data: deputyList = [] } = useIsUserDeputyQuery();
	const [primeServices, setPrimeServices] = useState<IProp>();
	const showPicas =
		isAdmin || primeServices?.userResponsible === currentUser?.ntid || currentUser?.ntid && primeServices?.deputy && primeServices?.deputy.split(',').includes(currentUser?.ntid) || deputyList.map((item) => item.ids).includes(Number(params.costCenterId));
	// const [noResult, setNoResult] = useState(false);
	const currentCostCenter = useMemo(() => {
		return serviceList.find((_costCenter) => _costCenter.id === costCenterId);
	}, [serviceList]);

	const [monthlyClusteredData, setMonthlyClusteredData] = useState<
		Partial<IServiceMonthlyData>[]
	>([]);
	const servicesWithoutPicas: IService[] | undefined = useMemo(() => {
		let output: IService[] = primeServices ? primeServices.services : [];

		// output = isClusterEnabled
		// 	? clusterService(
		// 			output?.filter((_service) => _service.serviceName !== 'Picas Report')
		// 	  )
		// 	: output;

		output = output?.filter((_service) => _service.serviceName !== 'Picas Report')

		const searchResult =
			searchTerm !== ''
				? output?.filter(
					(items) =>
						Object.values(items).findIndex((chunk: any) =>
							chunk
								.toString()
								.toLowerCase()
								.includes(searchTerm.toLowerCase())
						) !== -1 ||
						dualSearch(
							Object.values(items).join('').toLowerCase(),
							searchTerm.toLowerCase().split('+')
						)
				)
				: [];

		console.log('Search Result :: ', searchResult);
		// if (searchResult.length === 0) {
		// 	setNoResult(true);
		// } else {
		// 	setNoResult(false);
		// }
		output = searchResult?.length !== 0 ? searchResult : output;

		if (
			homeFilters.sortServices.field !== '' &&
			homeFilters.sortServices.order !== ''
		) {
			console.log('Sorting by field and order');
			// const ifPicasPresent = output.filter(
			// 	(_item) => _item.serviceName === 'Picas Report'
			// );

			output = sortingFunction(
				output.filter((_item) => _item.serviceName !== 'Picas Report'),
				homeFilters.sortServices.field,
				homeFilters.sortServices.order
			);

			// output = [...output, ...ifPicasPresent];
		}

		// if (
		// 	isAdmin ||
		// 	currentUser?.ntid.toLocaleLowerCase() === currentCostCenter?.user_responsible.toLocaleLowerCase()
		// ) {
		// 	console.log("******* Check service is not Picas *******")
		// 	console.log("isAdmin ::" ,isAdmin)
		// 	console.log("currentUser.ntid ::", currentUser?.ntid)
		// 	console.log("currentCostCenter.userResponsible ::", currentCostCenter?.user_responsible)
		// 	console.log("******************************************")
		// } else {
		// 	output =
		// 		output.filter(
		// 			(_item) => _item.serviceName.toLocaleLowerCase() !== 'picas report'
		// 		);
		// }
		console.log('Runnning Memo');
		return output;
	}, [
		primeServices,
		_services,
		_archiveServices,
		isClusterEnabled,
		searchTerm,
		homeFilters.sortServices,
	]);

	const onlyPicas = useMemo(() => {
		return (
			primeServices &&
			primeServices.services.filter(
				(_service: any) => _service.serviceName === 'Picas Report'
			)
		);
	}, [primeServices]);

	const onRowEditChange = (e: any) => {
		setEditingRows(e.data);
	};

	const setActiveRowIndex = (index: any) => {
		let _editingRows = {
			...editingRows,
			...{ [index]: true },
		};
		setEditingRows(_editingRows);
	};
	const [showEdit, setShowEdit] = useState(false);
	const [editIO, setEditIO] = useState();

	const [monthlyData, setMonthlyData] = useState<IServiceDetails>();

	const [getServiceData, { isLoading: isFetchingServiceData }] =
		useGetAllMonthlyServiceDataMutation();

	const [getServiceDataArchive, { isLoading: isFetchingArchiveServiceData }] =
		useGetAllMonthlyServiceDataArchiveMutation();
	const getMonthlyData = async function (service: IService) {
		try {
			if (pageName === PAGES.SERVICES || pageName === PAGES.SERVICES_103) {
				let monthlyData = await getServiceData({
					additionalInformation: checkAzureService(service),
					cc_Id: Number(params.costCenterId),
					comment: 'null',
					said: service.said,
					serviceId: service.serviceId,
					serviceName: service.serviceName,
					versionId: 0,
					year: 0,
					ou
				}).unwrap();
				console.log('Monthly Data Without Clustering ::', monthlyData);
				return monthlyData;
			} else {
				let monthlyData = await getServiceDataArchive({
					additionalInformation: checkAzureService(service),
					cc_Id: Number(params.costCenterId),
					comment: 'null',
					said: service.said,
					serviceId: service.serviceId,
					serviceName: service.serviceName,
					versionId: Number(params.versionId),
					year: 0,
					ou
				}).unwrap();
				console.log('Monthly Data Without Clustering ::', monthlyData);
				return monthlyData;
			}
		} catch (e) { }
	};

	let navigate = useNavigate();

	const [selectedService, SetSelectedService] = useState<IService>();

	const [costCenters, setCostCenters] = useState([]);
	const [services, setServices] = useState(null);
	const [serviceData, setServiceData] = useState(null);
	const [displayBasic, setDisplayBasic] = useState(false);
	const [position, setPosition] = useState('center');
	const [displayPosition, setDisplayPosition] = useState(false);

	const dialogFuncMap = {
		displayBasic: setDisplayBasic,
		displayPosition: setDisplayPosition,
	};

	const onShow = (
		name: 'displayBasic' | 'displayPosition',
		position?: string
	) => {
		dialogFuncMap[`${name}`](true);

		if (position) {
			setPosition(position);
		}
	};

	const onHide = (name: 'displayBasic' | 'displayPosition') => {
		dialogFuncMap[`${name}`](false);
	};

	const actions = (rowData: any) => {
		const isPicas = rowData.serviceName === 'Picas Report';
		const index = editList.findIndex((fi: any) => fi.id_pk === rowData.id_pk);
		return (
			<div className="table-menu">
				{index !== -1 ? (
					isLoading ? (
						<ProgressSpinner />
					) : (
						<>
							<Button
								className="p-button-rounded p-button-text p-button-icon-only"
								tooltipOptions={{ position: 'left' }}
								tooltip="Reset ILV Comment"
								onClick={() => {
									setEditList([
										...editList.map((item: any) =>
											item.id_pk === rowData.id_pk
												? { id_pk: rowData.id_pk, ioNumber: rowData.ioNumber }
												: item
										),
									]);
								}}
							>
								<DisplayIcon icon="reset" className="table-action" />
							</Button>
							{editList[index].ioNumber !== rowData.ioNumber ? (
								<Button
									className="p-button-rounded p-button-text p-button-icon-only"
									tooltipOptions={{ position: 'left' }}
									tooltip="Save ILV Comment"
									onClick={(e) => {
										e.stopPropagation();
										updateIONumber({
											cc_Id: Number(params.costCenterId),
											serviceList: rowData,
											ioNumber: editList[index].ioNumber,
											ou
										});
										setEditList([
											...editList.filter(
												(item: any) => item.id_pk !== rowData.id_pk
											),
										]);
									}}
								>
									<DisplayIcon icon={'tablerSave'} className="table-action" />
								</Button>
							) : (
								<Button
									className="p-button-rounded p-button-text p-button-icon-only"
									tooltipOptions={{ position: 'left' }}
									tooltip="Cancel IO Number Update"
									onClick={(e) => {
										e.stopPropagation();
										setEditList([
											...editList.filter(
												(item: any) => item.id_pk !== rowData.id_pk
											),
										]);
									}}
								>
									<DisplayIcon icon={'tablerCancel'} className="table-action" />
								</Button>
							)}
						</>
					)
				) : (
					<>
						{!isArchive && !isPicas && (
							<Button
								className="p-button-rounded p-button-text p-button-icon-only"
								tooltipOptions={{ position: 'right' }}
								tooltip="Edit IO Number"
								onClick={(e) => {
									e.stopPropagation();
									setEditList([
										...editList,
										{ id_pk: rowData.id_pk, ioNumber: rowData.ioNumber },
									]);
								}}
							>
								<DisplayIcon icon="tablerPencil" className="table-action" />
							</Button>
						)}
						<Button
							className="p-button-rounded p-button-text p-button-icon-only"
							tooltipOptions={{ position: 'right' }}
							tooltip="Display ILV & Service Comments"
							onClick={(e: any) => {
								console.log('Comments Data :: ', rowData);
								e.stopPropagation();
								setShowComments(true);
								setService(rowData);
								dispatch(setServiceOptions({ showComments: true }));
							}}
						>
							<DisplayIcon
								icon="tablerMessage"
								className={`table-action ${rowData.isComment || rowData.isILVComment
									? 'table-action__highlight'
									: ''
									}`}
							/>
						</Button>
						{!isPicas === true && rowData.isHistory && (
							<Button
								className="p-button-rounded p-button-text p-button-icon-only"
								tooltipOptions={{ position: 'left' }}
								tooltip="Display Cost Center Change History"
								onClick={async (e: any) => {
									e.stopPropagation();
									let history = await getHistory({
										cc_Id: Number(params.costCenterId),
										comment: 'null',
										said: rowData.said,
										serviceId: rowData.serviceId,
										serviceName: rowData.serviceName,
										additionalInformation: checkAzureService(rowData),
									}).unwrap();
									setHistory(history);
									setIsPopoverOpen(rowData.id_pk);
								}}
							>
								<DisplayIcon icon="tablerHistory" className="table-action" />
							</Button>
						)}
						{!isArchive && !isPicas && rowData.serviceName.includes("ServiceNow") && (
							<Button
								className="p-button-rounded p-button-text p-button-icon-only"
								tooltip="ServiceNow info"
								tooltipOptions={{ position: 'top' }}
								onClick={(e: any) => {
									e.stopPropagation();
									openInNewTab('https://servicenow.i.mercedes-benz.com/esc?id=kb_article&table=kb_knowledge&sys_kb_id=8ac9e871dbede91076c6000cd39619f9&spa=1')
								}}
							>
								<DisplayIcon className="table-action" icon="serviceInfo" />
							</Button>
						)}
						{isPopoverOpen === rowData.id_pk && (
							<PopOver
								callback={() => {
									setIsPopoverOpen(-1);
								}}
							>
								{historyTable}
							</PopOver>
						)}
					</>
				)}
			</div>
		);
	};

	const renderFooter = (name: 'displayBasic' | 'displayPosition') => {
		return (
			<div>
				<Button
					label="No"
					icon="pi pi-times"
					onClick={() => onHide(name)}
					className="p-button-text"
				/>
				<Button
					label="Yes"
					icon="pi pi-check"
					onClick={() => onHide(name)}
					autoFocus
				/>
			</div>
		);
	};

	const [editList, setEditList] = useState<any>([]);
	const [updateMode, setUpdateMode] = useState<'IONUMBER' | 'REASSIGNMENT'>(
		'IONUMBER'
	);

	const isCostController = useSelector(getIsCostController);

	const IONumber = (rowData: any) => {
		const index = editList.findIndex(
			(item: any) => item.id_pk === rowData.id_pk
		);
		if (index !== -1) {
			return (
				<InputText
					name={`io-number-${rowData.id_pk}`}
					value={editList[index].ioNumber}
					onChange={(e) => {
						console.log(e.target.value);
						setEditList([
							...editList.map((item: any) =>
								item.id_pk === rowData.id_pk
									? { id_pk: rowData.id_pk, ioNumber: e.target.value }
									: item
							),
						]);
					}}
				/>
			);
		} else {
			return rowData.ioNumber;
		}
	};

	const header = () => {
		return (
			<div className="flex align-items-center justify-content-start">
				<h5 className="mr-4">Bulk Update</h5>
				<RadioButton
					inputId="ioNumberUpdate"
					value="IONUMBER"
					name="updateMode"
					onChange={(e) => setUpdateMode(e.value)}
					checked={updateMode === 'IONUMBER'}
					className="mr-2"
				/>
				<label htmlFor="ioNumberUpdate">IO Number</label>
				{isAdmin || isCostController || currentUser?.ntid === primeServices?.userResponsible &&
					<>
						<RadioButton
							inputId="serviceReassignment"
							value="REASSIGNMENT"
							name="updateMode"
							onChange={(e) => setUpdateMode(e.value)}
							checked={updateMode === 'REASSIGNMENT'}
							className="mx-2"
						/>
						<label htmlFor="serviceReassignment">Service Reassignment</label>
						<h6 className="ml-5 font-bold">
							{`${selectedServices !== undefined ? selectedServices.length : 0
								} of ${servicesWithoutPicas?.filter(
									(_service) => _service.serviceName !== 'Picas Report'
								).length
								} selected`}
						</h6>
					</>
				}
				{updateMode === 'IONUMBER' ? (
					<>
						<InputText
							className="ml-5"
							value={bulkIONumber}
							onChange={(e) => setBulkIONumber(e.target.value)}
						/>

						<Button
							label="Update IO Number"
							icon="pi pi-check"
							className="p-button-sm ml-4"
							disabled={
								!selectedServices ||
								selectedServices?.length === 0 ||
								bulkIONumber.length === 0
							}
							onClick={() => {
								if (selectedServices) {
									updateIONumber({
										cc_Id: Number(params.costCenterId),
										serviceList: selectedServices,
										ioNumber: bulkIONumber,
										ou
									});
								}
							}}
						/>
					</>
				) : (
					<Button
						label="Reassign Services"
						icon="pi pi-check"
						className="p-button-sm ml-4"
						disabled={!selectedServices || selectedServices?.length === 0}
						onClick={() => {
							dispatch(setServiceOptions({ bulkReassignment: true }));
						}}
					/>
				)}{' '}
			</div>
		);
	};

	return (
		<>
			<ExtraActions></ExtraActions>
			<div className="flex flex-column lg:flex-row top-card align-items-center mb-4">
				<div className="w-full lg:w-3">
					{isLoadingServices || isLoadingArchivedServices ? (
						<Skeleton height="3rem" width="15rem" className="mb-2"></Skeleton>
					) : (
						<div className="flex align-items-center">
							<DisplayIcon
								icon="back"
								onClick={() =>
									navigate(pageName === PAGES.ARCHIVE_SERVICES ? '/archive' : ou === 98 ? '/actuals/itServices' : '/actuals/itServices_103', {
										state: { prevPath: location.pathname },
									})
								}
								className="action-icon flex mr-3"
							/>
							<div>
								<h2
									dangerouslySetInnerHTML={searchHighlighter(
										primeServices?.name,
										searchTerm
									)}
								></h2>
								<span
									className="primary-text costcenter-number"
									dangerouslySetInnerHTML={searchHighlighter(
										primeServices?.costCenter,
										searchTerm
									)}
								></span>
							</div>
						</div>
					)}
				</div>

				<div className="flex w-full lg:w-9 justify-content-between lg:justify-content-end mt-5 lg:mt-0">
					<div className="flex align-items-center justify-content-between">
						{isLoadingServices === true ||
							isLoadingArchivedServices === true ? (
							<Skeleton height="3rem" width="5rem"></Skeleton>
						) : (
							<>
								<DisplayIcon icon="booked" className="b-icon green-svg" />
								<div className="flex flex-column-reverse align-items-center ml-3">
									<span className="green-text-muted">Booked</span>
									<h2 className="green-text">
										{servicesWithoutPicas
											? servicesWithoutPicas
												.filter(
													(_service) =>
														_service.serviceName !== 'Picas Report'
												)
												.reduce(
													(acc: number, item: any) =>
														(acc += item.bookedAmount),
													0
												)
												.toLocaleString('de-DE')
											: 0}{' '}
										€
									</h2>
								</div>
							</>
						)}
					</div>
					<div
						className="flex align-items-center justify-content-between"
						style={{
							borderLeft: '1px solid #e7e9f0',
							paddingLeft: '2rem',
							marginLeft: '2rem',
						}}
					>
						{isLoadingServices || isLoadingArchivedServices ? (
							<Skeleton height="3rem" width="5rem" className="mb-2"></Skeleton>
						) : (
							<>
								<DisplayIcon icon="planned" className="b-icon primary-svg" />

								<div className=" flex flex-column-reverse align-items-center ml-3">
									<span className="primary-muted-text">Planned</span>
									<h2 className="">
										{servicesWithoutPicas
											? servicesWithoutPicas
												.filter(
													(_service) =>
														_service.serviceName !== 'Picas Report'
												)
												.reduce(
													(acc: number, item: any) =>
														(acc += item.plannedAmount),
													0
												)
												.toLocaleString('de-DE')
											: 0}{' '}
										€
									</h2>
								</div>
							</>
						)}
					</div>

					{/** 
					 * commented below lines because of separating the Picas from Actuals
					 * CC-950: separate tab for IT-Services and PICAS
					 */}

					{/* {!(searchTerm.length > 0) &&
						onlyPicas &&
						onlyPicas.length > 0 &&
						searchTerm.length === 0 && (
							<div
								className="hidden lg:flex align-items-center justify-content-between"
								style={{
									borderLeft: '1px solid #e7e9f0',
									paddingLeft: '2rem',
									marginLeft: '2rem',
								}}
								onClick={() =>
									dispatch(setServiceOptions({ picasDetails: true }))
								}
							>
								{isLoadingServices || isLoadingArchivedServices ? (
									<Skeleton
										height="3rem"
										width="5rem"
										className="mb-2"
									></Skeleton>
								) : (
									<>
										<DisplayIcon icon="picas" className="b-icon primary-svg" />

										<div className="flex flex-column-reverse align-items-center ml-3">
											<span className="primary-muted-text">Picas</span>
											<h2 className="">
												{onlyPicas[0].bookedAmount
													? onlyPicas[0].bookedAmount.toLocaleString('de-DE')
													: 0}{' '}
												€
											</h2>
										</div>
									</>
								)}
							</div>
						)} */}
				</div>
			</div>

			{searchTerm.length !== 0 && servicesWithoutPicas.length === 0 ? (
				<Message
					severity="warn"
					content={
						<div className="flex align-items-center">
							<DisplayIcon icon="tablerWarning" className="mr-3" />
							No results found for the term{' '}
							<span className="bold-text ml-2">{searchTerm}</span> , Displaying
							all results
						</div>
					}
					className="my-5 ml-3"
				></Message>
			) : null}
			<div className="grid">
				{isLoadingServices || isLoadingArchivedServices ? (
					<CardSkeleton />
				) : homeFilters.viewType === 'list' ? (
					servicesWithoutPicas?.map((service) => (
						<div
							className="col-12 xl:col-6"
							key={service.id_pk}
							onClick={async () => {
								if (
									window.getSelection() &&
									window.getSelection()!.toString().length > 0
								)
									return true;

								setId(service.id_pk);
								if (service.serviceName !== 'Picas Report') {
									console.log(
										'Is Clustered :: ',
										isClusterEnabled && Array.isArray(service.clusterInfo)
									);

									SetSelectedService(service);
									setCurrentS(service);

									if (isClusterEnabled && Array.isArray(service.clusterInfo)) {
										let _storage: any = {};
										for (let _clusterInfo of service.clusterInfo) {
											const payload = {
												...service,
												additionalInfo: _clusterInfo,
											};
											let md = await getMonthlyData(payload);
											if ('data' in _storage) {
												console.log(':: Storing Sec. Storage ::');
												if (md && md.data) {
													_storage = {
														..._storage,
														data: [..._storage.data, ...md.data],
													};
													// _storage = [..._storage, ...md.data];
												}
											} else {
												_storage = {
													...md,
												};
											}

											//setMonthlyClusteredData(_storage);

											// console.log(
											// 	':: Existing Data ::',
											// 	monthlyClusteredData
											// );
											//	console.log(':: Fetching Next ::');
										}
										setMonthlyData(_storage);
										console.log(':: Data For Fetched Services ::', monthlyData);
									} else {
										setMonthlyData(await getMonthlyData(service));
									}

									console.log('Monthly Data :: ', monthlyData);
									console.log(
										'Clustered Monthly Data :: ',
										monthlyClusteredData
									);
									console.log('Selected Data :: ', currentS);

									dispatch(setServiceOptions({ serviceDetails: true }));
								} else {
									// dispatch(setServiceOptions({ picasDetails: true }));
								}
							}}
						>
							{currentCostCenter && (
								<ServiceCard
									isFetchingData={
										id === service.id_pk &&
										(isFetchingServiceData || isFetchingArchiveServiceData)
									}
									service={service}
									costCenter={currentCostCenter}
									searchTerm={searchTerm}
									isArchive={isArchive}
								/>
							)}
						</div>
					))
				) : homeFilters.viewType === 'tableEdit' ? (
					<div className="cards">
						<DataTable
							value={servicesWithoutPicas.filter(
								(_service) => _service.serviceName !== 'Picas Report'
							)}
							header={header}
							responsiveLayout="scroll"
							dataKey="id_pk"
							rowHover
							selection={selectedServices}
							onSelectionChange={(e) => setSelectedServices(e.value)}
							loading={isLoadingServices || isLoadingArchivedServices}
						>
							<Column
								selectionMode="multiple"
								headerStyle={{ width: '3em' }}
							></Column>
							<Column
								header="#"
								alignHeader="left"
								body={(data, props) => props.rowIndex + 1}
							></Column>
							<Column field="said" header="SAID" sortable></Column>
							<Column
								field="serviceName"
								header="Service Name"
								body={(rowData) => (
									<span
										dangerouslySetInnerHTML={searchHighlighter(
											rowData.serviceName,
											searchTerm
										)}
									></span>
								)}
								sortable
							></Column>
							<Column
								field="serviceId"
								header="Service ID"
								body={(rowData) => (
									<span
										dangerouslySetInnerHTML={searchHighlighter(
											rowData.serviceId,
											searchTerm
										)}
									></span>
								)}
								sortable
							></Column>
							<Column
								field="additionalInfo"
								header="Additional Info"
								style={{ overflowWrap: 'break-word' }}
								body={(rowData) => (
									<span
										dangerouslySetInnerHTML={searchHighlighter(
											rowData.additionalInfo,
											searchTerm
										)}
									></span>
								)}
								sortable
							></Column>
							<Column
								field="servicetype"
								header="Service Type"
								sortable
							></Column>
							<Column
								field="bookedAmount"
								header="Booked"
								body={(data) => formatCurrency(data.bookedAmount)}
								sortable
							></Column>
							<Column
								field="plannedAmount"
								header="Planned"
								body={(data) => formatCurrency(data.plannedAmount)}
								sortable
							></Column>

							{/* cc-918: hide forecast */}
							<Column
								field="yearlyTotal"
								header="Forecast"
								body={(data) => formatCurrency(data.yearlyTotal)}
								sortable
							></Column>
							<Column field="ioNumber" header="IO #" sortable></Column>
						</DataTable>
					</div>
				) : (
					<div className="cards">
						<DataTable
							tableClassName="table-services"
							value={servicesWithoutPicas}
							responsiveLayout="scroll"
							rowHover
							editMode="row"
							dataKey="id_pk"
							editingRows={editingRows}
							onRowEditChange={onRowEditChange}
							loading={isLoadingServices || isLoadingArchivedServices}
							onRowClick={async ({ data: service }) => {
								if (
									window.getSelection() &&
									window.getSelection()!.toString().length > 0
								)
									return true;
								setId(service.id_pk);
								if (service.serviceName !== 'Picas Report') {
									console.log(
										'Is Clustered :: ',
										isClusterEnabled && Array.isArray(service.clusterInfo)
									);

									SetSelectedService(service);
									setCurrentS(service);
									if (isClusterEnabled && Array.isArray(service.clusterInfo)) {
										let _storage: any = {};
										for (let _clusterInfo of service.clusterInfo) {
											const payload = {
												...service,
												additionalInfo: _clusterInfo,
											};
											let md = await getMonthlyData(payload);
											if ('data' in _storage) {
												if (md && md.data) {
													_storage = {
														..._storage,
														data: [..._storage.data, ...md.data],
													};
												}
											} else {
												_storage = { ...md };
											}

											//setMonthlyClusteredData(_storage);
											//	console.log(':: Existing Data ::', monthlyClusteredData);
											//	console.log(':: Fetching Next ::');
										}
										setMonthlyData(_storage);

										//console.log(':: Fetched Data ::', monthlyClusteredData);
									} else {
										setMonthlyData(await getMonthlyData(service));
									}

									console.log('Monthly Data :: ', monthlyData);
									console.log(
										'Clustered Monthly Data :: ',
										monthlyClusteredData
									);
									console.log('Selected Data :: ', currentS);

									dispatch(setServiceOptions({ serviceDetails: true }));
								} else {
									// dispatch(setServiceOptions({ picasDetails: true }));
								}
							}}
						>
							<Column
								header="#"
								alignHeader="left"
								body={(data, props) => props.rowIndex + 1}
							></Column>
							<Column field="said" header="SAID" sortable></Column>
							<Column
								field="serviceName"
								header="Service Name"
								body={(rowData) => (
									<span
										dangerouslySetInnerHTML={searchHighlighter(
											rowData.serviceName,
											searchTerm
										)}
									></span>
								)}
								sortable
							></Column>
							<Column
								field="serviceId"
								header="Service ID"
								body={(rowData) => (
									<span
										dangerouslySetInnerHTML={searchHighlighter(
											rowData.serviceId,
											searchTerm
										)}
									></span>
								)}
								sortable
							></Column>
							<Column
								field="additionalInfo"
								header="Additional Info"
								style={{ overflowWrap: 'break-word' }}
								body={(rowData) => (
									<span
										dangerouslySetInnerHTML={searchHighlighter(
											rowData.additionalInfo,
											searchTerm
										)}
									></span>
								)}
								sortable
							></Column>
							<Column
								field="servicetype"
								header="Service Type"
								sortable
							></Column>
							<Column
								field="bookedAmount"
								header="Booked"
								body={(data) => formatCurrency(data.bookedAmount)}
								sortable
							></Column>
							<Column
								field="plannedAmount"
								header="Planned"
								body={(data) => formatCurrency(data.plannedAmount)}
								sortable
							></Column>

							{/* cc-918: hide forecast */}
							<Column
								field="yearlyTotal"
								header="Forecast"
								body={(data) => formatCurrency(data.yearlyTotal)}
								sortable
							></Column>

							<Column
								field="ioNumber"
								header="IO #"
								body={IONumber}
								sortable
							></Column>
							<Column align="center" body={actions}></Column>
						</DataTable>
					</div>
				)}

				{selectedService && (
					<ServiceDetailsDialog
						monthlyData={monthlyData}
						monthlyClusteredData={monthlyClusteredData}
						selectedService={selectedService}
						costCenterId={costCenterId}
						isArchive={isArchive}
						showSwap={
							isAdmin || isCostController ||
							currentUser?.ntid.toLowerCase() ===
							primeServices?.userResponsible.toLocaleLowerCase() || primeServices?.deputy.split(',').includes(currentUser?.ntid!)
						}
					/>
				)}

				{reAssignDialog && monthlyData && currentCostCenter && (
					<ReassignDialog
						monthlyData={monthlyData}
						costCenter={currentCostCenter}
					/>
				)}
				{updateMode === 'REASSIGNMENT' &&
					selectedServices !== undefined &&
					currentCostCenter && (
						<BulkReassignDialog
							selectedServices={selectedServices}
							costCenter={currentCostCenter}
							removeService={(serviceToRemove) => {
								if (serviceToRemove === 'ALL') {
									setSelectedServices(undefined);
								} else {
									setSelectedServices(
										selectedServices.filter((_item) => {
											if (
												_item.serviceId === serviceToRemove.serviceId &&
												_item.serviceName === serviceToRemove.serviceName &&
												_item.said === serviceToRemove.said &&
												_item.additionalInfo === serviceToRemove.additionalInfo
											) {
												return false;
											} else {
												return true;
											}
										})
									);
								}
							}}
						/>
					)}
				{onlyPicas && allModals.picasDetails === true && showPicas && <PicasDialog />}
				{showComments && currentCostCenter && (
					<CommentsDialog
						costCenter={currentCostCenter}
						service={service}
						isArchive={isArchive}
						callback={() => setShowComments(false)}
					/>
				)}
			</div>
			<ScrollTop />
		</>
	);
};

export default ServicePage;
